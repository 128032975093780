import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6497ed6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "circleLabel" }
const _hoisted_5 = { class: "slotBase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_LoginContainer = _resolveComponent("LoginContainer")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'b' + _ctx.state.id,
    class: "loginExpBtn"
  }, [
    _createElementVNode("div", {
      id: 's' + _ctx.state.id,
      class: "bgscreen",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.ed && _ctx.ed(...args)))
    }, null, 8, _hoisted_2),
    _createElementVNode("div", {
      id: _ctx.state.id,
      class: _normalizeClass(["circle", {
        notexp: !_ctx.state.expflg && !_ctx.state.isAnimate
      }]),
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.st && _ctx.st(...args))),
      onKeyup: _cache[2] || (_cache[2] = _withKeys(
//@ts-ignore
(...args) => (_ctx.ed && _ctx.ed(...args)), ["esc"])),
      tabindex: "0"
    }, [
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Icon, {
          class: "LoginIcon",
          src: _ctx.LOGIN,
          size: 25
        }, null, 8, ["src"])
      ], 512), [
        [_vShow, !_ctx.state.expflg]
      ]),
      _withDirectives(_createElementVNode("span", _hoisted_5, [
        _createVNode(_component_LoginContainer)
      ], 512), [
        [_vShow, _ctx.state.expflg]
      ])
    ], 42, _hoisted_3)
  ], 8, _hoisted_1))
}
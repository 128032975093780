
import Btn from '@/components/Atoms/Btn.vue'
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'Home',
  components: {
    Btn
  },
  setup () {
    const store = useStore()
    const helthStatus = computed(() => store.getters['system/getHelth']())
    const helthCheck = (): void => {
      store.dispatch('system/helthCheck')
    }
    return {
      helthCheck,
      helthStatus
    }
  }
})


import {
  computed,
  reactive,
  onMounted,
  defineComponent
} from 'vue'

import { useStore } from 'vuex'
import LoginExpBtn from '@/components/Organisms/LoginExpBtn.vue'

type LoginState = {
  bool: boolean
}
export default defineComponent({
  name: 'Index',
  components: {
    LoginExpBtn
  },
  setup () {
    const store = useStore()
    const isPage = computed(() => store.getters['system/isPage']())
    const state: LoginState = reactive({
      bool: true
    })

    onMounted(() => {
      console.log('mounted/Index')
    })

    return {
      isPage,
      state
    }
  }
})

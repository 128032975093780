
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Btn from '@/components/Atoms/Btn.vue'
import TextInput from '@/components/Molecules/TextInput.vue'
// import Linker from '@/components/Atoms/Linker.vue'

import {
  computed,
  reactive,
  watch,
  onMounted,
  defineComponent
} from 'vue'

type AuthInfo = {
  mail: string,
  password: string
}

type LoginContainerState = {
  auth: AuthInfo,
  isEmit: boolean
}

export default defineComponent({
  name: 'Login',
  components: {
    Btn,
    TextInput
    // Linker
  },
  setup () {
    const store = useStore()
    const router = useRouter()

    const state: LoginContainerState = reactive({
      auth: {
        mail: '',
        password: ''
      },
      isEmit: false
    })

    // computed
    const isAuth = computed(() => store.getters['auth/isLogin']())
    const isFailed = computed(() => store.state.auth.isFailed)

    // watch
    watch(isAuth, () => {
      console.log('isAuth ?', isAuth.value)
      if (isAuth.value) {
        console.log('isAuth === true')
        state.isEmit = false
        router.push('/home')
      }
    })

    watch(isFailed, () => {
      state.isEmit = false
    })

    // Mounted
    onMounted(() => {
      console.log('-- called Mounted ---')
    })

    return {
      state,
      isAuth,
      isFailed,
      login: () => {
        if (state.isEmit) return
        state.isEmit = true
        store.dispatch('auth/login', state.auth)
      }
    }
  }
})



import {
  computed,
  reactive,
  ref,
  defineComponent,
  watch
} from 'vue'

import eye from '@/assets/eye.svg'

export default defineComponent({
  name: 'TextInput',
  props: {
    modelValue: String,
    label: String,
    isFail: Boolean,
    type: String,
    err: Boolean,
    isEye: Boolean,
    isShow: Boolean
  },
  setup (props, ctx) {
    const state = reactive({
      isFocus: false,
      isEdit: false,
      isFail: false,
      isEye: false,
      isShow: false
    })

    if (props.type === 'date') state.isFocus = true

    const input = ref()

    const inputVal = computed({
      get (): string | undefined { return props.modelValue },
      set (v): void { ctx.emit('update:modelValue', v) }
    })

    const getType = (): string => {
      if (props.type === 'password') {
        return state.isEye ? 'text' : 'password'
      }
      return 'text'
    }

    if (inputVal.value) state.isFocus = true

    watch(() => inputVal.value, () => {
      if (typeof inputVal.value !== 'string') return
      const len = inputVal.value.length
      state.isShow = len > 0
    })

    return {
      state,
      inputVal,
      input,
      eye,
      getType,
      onFocus: () => {
        console.log('TextInput onFocus')
        state.isFocus = true
        state.isEdit = true
        console.dir(state)
      },
      onBlur: () => {
        console.log('TextInput onBlur')
        state.isEdit = false
        state.isFocus = !!inputVal.value
        console.dir(state)
      },
      setFocus: () => {
        console.log('TextInput setFocus')
        state.isFocus = true
        if (input.value) input.value.focus()
        console.dir(state)
      }
    }
  }
})
